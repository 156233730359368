import React from "react"
import { Helmet } from "react-helmet"
import "./index.css"
import Layout from "../components/layout"

const IndexPage = () => (
  <>
    <Layout type="home" />
    <Helmet>
      <meta charSet="utf-8" />
      <title>Lenx.ai</title>
    </Helmet>
  </>
)

export default IndexPage
